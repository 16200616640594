<script lang="ts" setup>
import { ref, computed, watch, getCurrentInstance } from "vue";
import { getPartyDetailByReference } from "@/services/api/party.api";
import useDateTimeFormatter from "@/composables/formatDateTime.js";
import LocationOverview from "@/components/location/LocationOverviewGrid.vue";
import PartyPbiOverview from "@/components/party/PartyPowerBIOverview.vue";

const { formatDateUtcD, formatDateUtcDT } = useDateTimeFormatter();

const props = defineProps({
  id: String,
  partyReference: String,
  addModus: {
    type: Boolean,
    default: false,
  },
});

const party = ref({
  id: undefined,
  reference: 0,
  contacts: [],
});

const logoName = computed(() => {
  return (process.env.VUE_APP_PORTAL_URL ? process.env.VUE_APP_PORTAL_URL : "/") + "api/asset/logo/" + props.partyReference;
});
const contactList = computed(() => {
  const list = [...party.value.contacts];
  list.sort((a, b) => a.partyRoleDescription.localeCompare(b.partyRoleDescription));
  return list;
});

watch(
  () => props.partyReference,
  () => {
    fetchData();
  },
  { immediate: true }
);

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function fetchData() {
  const response = await getPartyDetailByReference(props.partyReference);

  console.log("%cfetchData, response: ", "background:yellow; color:red", response.data);
  if (response && !response.error) {
    party.value = response.data;
  } else {
    proxy.$toaster.error("Ophalen van de data is mislukt! " + response.error);
  }
}
</script>

<template>
  <v-container fluid pa-0>
    <header cols="auto" class="app-welcome-message">
      <h2>
        <v-btn v-if="party.id" :to="{ name: 'PartyDetails', params: { partyReference: party.reference.toString() } }" icon small plain>
          <v-icon small> mdi-pencil </v-icon>
        </v-btn>
        Overzicht
      </h2>
    </header>
    <v-row>
      <v-col>
        <masonry :cols="{ default: 2, 1264: 1 }" :gutter="12">
          <v-container class="boxed" fluid :class="{ noBorders: false }">
            <header>Geldigheid</header>
            <v-row>
              <v-col cols="4"><label>Geldig van</label></v-col>
              <v-col> {{ formatDateUtcD(party.valFrom) }}</v-col>
              <v-col> <label>Geldig tot</label></v-col>
              <v-col> {{ formatDateUtcD(party.valTill) }}</v-col>
            </v-row>
            <v-row v-if="party.detailCnt > 1">
              <v-col cols="4"><label>Versies</label></v-col>
              <v-col> {{ party.detailCnt }}</v-col>
            </v-row>
            <v-row v-if="party.detailCnt > 1">
              <v-col cols="4"><label>Begindatum</label></v-col>
              <v-col> {{ formatDateUtcD(party.dateFrom) }}</v-col>
              <v-col> <label>Einddatum</label></v-col>
              <v-col> {{ formatDateUtcD(party.dateTill) }}</v-col>
            </v-row>
          </v-container>

          <v-container class="boxed" :class="{ noBorders: false }">
            <header>Aanpassing</header>
            <v-row>
              <v-col cols="4"><label>Door</label></v-col>
              <v-col>
                {{ party.modBy }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4"><label>Omschrijving</label></v-col>
              <v-col>
                {{ party.modDescr }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4"><label>Datum</label></v-col>
              <v-col>
                {{ formatDateUtcDT(party.modDat) }}
              </v-col>
            </v-row>
          </v-container>
        </masonry>
        <masonry :cols="{ default: 3, 768: 2, 512: 1 }" :gutter="12">
          <v-container class="boxed">
            <header>Algemene gegevens</header>
            <v-row>
              <v-col cols="auto">
                <img :src="logoName" style="max-width: 200px; max-height: 200px" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4"><label>Referentie (INNAX-ID)</label></v-col>
              <v-col>
                {{ party.reference }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4"><label>Parent</label></v-col>
              <v-col>
                {{ party.parName }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4"><label>Naam</label></v-col>
              <v-col>
                {{ party.name }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4"><label>Omschrijving</label></v-col>
              <v-col>
                {{ party.descr }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4"><label>Type</label></v-col>
              <v-col>
                {{ party.typeDescr }}
              </v-col>
            </v-row>
          </v-container>

          <v-container class="boxed">
            <header>Energieprijzen</header>
            <v-row>
              <v-col cols="4"><label>Elektriciteit</label></v-col>
              <v-col cols="4">
                <!-- 
                  Uitzoeken:
                  Nu wordt nog overal een punt als decimal separator getoond, vandaar dat deze hier vooralsnog ook nog expliciet gebruikt wordt.
                  Gebruik en tonen van komma moet anders opgelost worden, a.d.h.v. browser language.
                  Zie user story 8469.
                -->
                <span v-if="party.priceElec != null">{{ party.priceElec | currency("€ ", 4, { thousandsSeparator: " ", decimalSeparator: "." }) }} /kWh</span>
                <!-- <span v-if="party.priceElec != null">{{ party.priceElec | number("0,0", { thousandsSeparator: "." }) }} €/kWh</span> -->
                <span v-else>-</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4"><label>Gas</label></v-col>
              <v-col cols="4">
                <span v-if="party.priceGas != null">{{ party.priceGas | currency("€ ", 4, { thousandsSeparator: " ", decimalSeparator: "." }) }} /m³</span>
                <!-- <span v-if="party.priceGas != null">{{ party.priceGas | number("0,0", { thousandsSeparator: "." }) }} €/m³</span> -->
                <span v-else>-</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4"><label>Biomassa</label></v-col>
              <v-col cols="4">
                <span v-if="party.priceBio != null">{{ party.priceBio | currency("€ ", 4, { thousandsSeparator: " ", decimalSeparator: "." }) }} /m³</span>
                <!-- <span v-if="party.priceBio != null">{{ party.priceBio | number("0,0", { thousandsSeparator: "." }) }} €/m³</span> -->
                <span v-else>-</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4"><label>Warmte</label></v-col>
              <v-col cols="4">
                <span v-if="party.priceHeat != null">{{ party.priceHeat | currency("€ ", 4, { thousandsSeparator: " ", decimalSeparator: "." }) }} /GJ</span>
                <!-- <span v-if="party.priceHeat != null">{{ party.priceHeat | number("0,0", { thousandsSeparator: "." }) }} €/GJ</span> -->
                <span v-else>-</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4"><label>Koude</label></v-col>
              <v-col cols="4">
                <span v-if="party.priceCold != null">{{ party.priceCold | currency("€ ", 4, { thousandsSeparator: " ", decimalSeparator: "." }) }} /GJ</span>
                <!-- <span v-if="party.priceCold != null">{{ party.priceCold | number("0,0", { thousandsSeparator: "." }) }} €/GJ</span> -->
                <span v-else>-</span>
              </v-col>
            </v-row>
          </v-container>

          <v-container class="boxed">
            <header>Verduurzamingsradar</header>
            <v-row>
              <v-col cols="6"><label>Strategie</label></v-col>
              <v-col cols="4">
                <span v-if="party.radarStrategy != null">{{ party.radarStrategy }}</span>
                <span v-else>-</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6"><label>Inzicht</label></v-col>
              <v-col cols="4">
                <span v-if="party.radarInsight != null">{{ party.radarInsight }}</span>
                <span v-else>-</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6"><label>Gedeelde waarden</label></v-col>
              <v-col cols="4">
                <span v-if="party.radarSharedValues != null">{{ party.radarSharedValues }}</span>
                <span v-else>-</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6"><label>Status</label></v-col>
              <v-col cols="4">
                <span v-if="party.radarStatus != null">{{ party.radarStatus }}</span>
                <span v-else>-</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6"><label>Slagkracht</label></v-col>
              <v-col cols="4">
                <span v-if="party.radarStrikingPower != null">{{ party.radarStrikingPower }}</span>
                <span v-else>-</span>
              </v-col>
            </v-row>
          </v-container>
          <v-container class="boxed d-none"> </v-container>
          <v-container class="boxed">
            <header>Contacten</header>

            <v-row v-for="(item, index) in contactList" v-bind:key="index">
              <v-col cols="4">{{ item.partyRoleDescription }}</v-col>
              <v-col>{{ item.userDescription }}</v-col>
            </v-row>
          </v-container>
          <v-container class="boxed">
            <header>Herkomst</header>
            <v-row>
              <v-col cols="4"><label>Bron</label></v-col>
              <v-col>
                {{ party.sourceTypeDescr }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4"><label>Bron referentie</label></v-col>
              <v-col>
                {{ party.sourceReference }}
              </v-col>
            </v-row>
          </v-container>
        </masonry>
      </v-col>
    </v-row>
    <v-row class="overview-wrapper">
      <v-col cols="2">
        <header class="app-welcome-message">
          <h2>Locaties</h2>
        </header>
      </v-col>
    </v-row>
    <v-row class="overview-wrapper">
      <v-col cols="12">
        <location-overview v-if="party.reference" :partyReference="party.reference" :allowMutationAddress="false" :allowConnectAddressToParty="true" :explicitPageSize="10"></location-overview>
      </v-col>
    </v-row>
    <v-row class="overview-wrapper">
      <v-col cols="2">
        <header class="app-welcome-message">
          <h2>PowerBI koppelingen</h2>
        </header>
      </v-col>
    </v-row>
    <v-row class="overview-wrapper">
      <v-col cols="12">
        <party-pbi-overview v-if="party.reference" :partyReference="party.reference" :allowMutationAddress="false" :allowConnectAddressToParty="true" :explicitPageSize="10"></party-pbi-overview>
      </v-col>
    </v-row>
  </v-container>
</template>
